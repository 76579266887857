import React from "react";
/** Helper */
import { isError, getErrorMsg } from "../../../../helper/FormHelper.jsx";
/** Component */
import { FormControl, FormHelperText } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";

export default function FormikDateTimePicker({ formik, elementContent }) {
  const dateError = isError(formik, elementContent.props.id);

  const handleChange = (newValue) => {
    formik.setFieldValue(elementContent.props.id, newValue);
  };

  return (
    <FormControl error={dateError} fullWidth>
      <DateTimePicker
        {...elementContent.props}
        value={formik.values[elementContent.props.id] ?? null}
        onChange={handleChange}
        onClose={() => (formik.touched[elementContent.props.id] = true)}
        renderInput={(params) => <TextField {...params} error={dateError} />}
        // 控制日期、時間和日期時間模式
        views={elementContent.views || ["year", "day", "hours", "minutes"]}
        inputFormat={elementContent.props.inputFormat || "YYYY/MM/DD hh:mm"}
      />
      <FormHelperText>
        {getErrorMsg(formik, elementContent.props.id)}
      </FormHelperText>
    </FormControl>
  );
}
