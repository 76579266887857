import React from "react";
import {
    Typography,
    Grid,
    Stack,
    Box,
} from "@mui/material";
import DashboardSkillStatCard from "./DashboardSkillStatCard";

const getSkillName = (skillData) => {
    if (skillData === undefined) {
        return "";
    }

    if (skillData.name_zh !== undefined) {
        return skillData.name_zh;
    }

    return skillData.code;
}


const Component = ({
    data,
}) => {

    // let totalPlayCount = convertToInteger(data?.all_time?.total_play_count);
    // let totalPlayTime = convertToHour(data?.all_time?.total_play_second);
    // let newPlayCount = convertToInteger(data?.one_day?.total_play_count);
    // let newPlayTime = convertToHour(data?.one_day?.total_play_second);

    // // Add comma to value 
    // let valueStr = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // let iconComponent = GetIcon("school");

    const gridSx = {
        px: 2,
        // height: "100%",      // ken: cannot use height: 100 with stretch
        // backgroundColor: "red",
    }


    let gridData = [];

    for (const statItem of data) {
        let dataItem = {
            title: getSkillName(statItem),
            playCount: statItem.total_play_count,
            playTime: statItem.total_play_second,
        }
        gridData.push(dataItem);
    }



    // const gridData = [
    //     { title: "總參與人數", value: totalPlayCount, color: "#DEF249", icon: "people", },
    //     { title: "新參與人數", value: newPlayCount , color: "#97D955", icon: "people", formatFunc: addPlusSign },
    //     { title: "總參與時數(小時)", value: totalPlayTime, color: "#FFDE78", icon: "time" },
    //     { title: "新參與時數(小時)", value: newPlayTime, color: "#F5BD3D", icon: "time", formatFunc: addPlusSign },
    // ]

    return (
        <Grid container alignItems="stretch" rowGap={2} direction="row" sx={{
            justifyContent: "center",
            alignItems: "stretch",
        }}  >
            {
                gridData.map((data, index) => {
                    return (
                        <Grid item xs={6} sm={3} sx={gridSx} key={index}>
                            <DashboardSkillStatCard title={data.title} playCount={data.playCount} playTime={data.playTime} />
                        </Grid>
                    )
                })
            }
            {/* <Grid item xs={12}>
                <pre>
                    {JSON.stringify(data, null, 2)}
                </pre>
            </Grid> */}
        </Grid>
    );

}

export default Component;


