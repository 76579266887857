import React, { useState, useEffect } from "react";
/** Helper */
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  postDataRequestPromise,
  getDataRequestPromise,
} from "../../../helper/AxiosHelper";
import { getlocalStorageUserObject } from "../../../helper/LocalStorageHelper";
import { checkSuperAdminPermission } from "../../../helper/PermissionHelper";
/** Component */
import {
  Box,
  Grid,
  Typography,
  Container,
  Paper,
  Divider,
} from "@mui/material";
import CommonForm from "../../common/CommonForm";
import FormContent from "./aiCoachingSettingFormSchema.js";

/** Content */
const debug = false;
export default function AiCoachingSettingForm({ orgId = null }) {
  const [isReadyOnly, setIsReadOnly] = useState(false);
  // init formik
  const formik = useFormik(FormContent.FormikInstance);
  const onSubmitHandle = () => {
    let formData = formik.values;
    // console.log(device);
    if (!orgId) return toast.error("資料錯誤 Organization ID");
    if (isReadyOnly) return;

    postDataRequestPromise(`/appSetting/update/${orgId}`, formData)
      .then((res) => {
        // formik.resetForm();
        toast.success("App Setting 更新成功!");
      })
      .catch((err) => {
        // Please check the data structure
        const message = err?.data?.resultMessage.en || "";

        toast.error(`更新失敗! ${message}`);
        console.log(err);
      });
  };
  useEffect(() => {
    if (!orgId) return toast.error("資料錯誤 Organization ID");
    var user = getlocalStorageUserObject();
    let hasPerm = checkSuperAdminPermission(user);
    setIsReadOnly(!hasPerm);
    getDataRequestPromise(`/appSetting/show/${orgId}`)
      .then((res) => {
        if (res.result) {
          let formFieldList = Object.keys(
            FormContent.FormikInstance.initialValues
          );
          formFieldList.forEach((field) => {
            // console.log(field, record[field]);
            formik.setFieldValue(field, res.result[field]);
          });
        }
      })
      .catch((err) => {
        // Please check the data structure
        console.log(err);
      });
  }, []);

  return (
    <Box component="form" sx={{ mt: 3 }}>
      <Paper sx={{ px: 3, py: 5 }} elevation={3}>
        <Grid container rowSpacing={3} columnSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" ml={1} color={"text.secondary"}>
              AI Coaching App (iPad) 設定 (BETA)
            </Typography>
            <Divider sx={{ maxWidth: "40%", pt: 0.5 }} />
          </Grid>
          <Grid item xs={12}>
            <CommonForm
              readOnly={isReadyOnly}
              fromContent={FormContent}
              formik={formik}
              onSubmitHandle={onSubmitHandle}
              debug={debug}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
