import { Box, Typography, Grid, Divider, Stack } from "@mui/material";
import { inf } from "numeric";

const MAIN_FONT = "Noto Sans TC";

function memberName(memberData) {
  const { name, member } = memberData;

  let info = name;
  const { group } = member;

  if (group !== undefined && group !== '') {
    return `${group} ${name}`
  } else {
    return name
  }

  // return name;
}

const medalImageMap = {
  1: "/medal/gold.png",
  2: "/medal/silver.png",
  3: "/medal/bronze.png",
};


function MedalIcon({ rank }) {
  return medalImageMap[rank] ? (
    <Box
      component="img"
      src={medalImageMap[rank]}
      maxWidth={25}
      maxHeight={25}
    />
  ) : <></>;
  // return (
  //   <Box
  //     component="img"
  //     src="/medal/gold.png"
  //     maxWidth={64}
  //     maxHeight={64}
  //   />
  // )
}

const displayType = "name";
// const displayType = "groupNum";

function getMemberName(memberData) {
  if (memberData == null || memberData == undefined) {
    return "";
  }
  return memberData.name;
}

function getGroupInfo(m) {
  if (m == null || m == undefined) {
    return "";
  }
  let displayName = m.group + m.group_num;
  if (m.group == null || m.group === "") {
    displayName = "";
  }
  // use last 6 digits of phone number if empty
  if (displayName === "") {
    displayName = m.phone_num.slice(-6);
  }

  return displayName;
}


function getGroupNum(m) {
  if (m == null || m === undefined) {
    return "";
  }

  let groupStr = (m.group == null || m.group === "") ? "" : m.group;

  let groupNumStr = (m.group_num == null || m.group_num === "") ? "" : m.group_num;

  
  return `${groupStr}${groupNumStr}`;
}


function getDisplayInfo(memberData) {
  if (displayType === "groupNum") {
    return getGroupInfo(memberData);
  } else if (displayType === "name") {
    return `${getGroupNum(memberData)} ${getMemberName(memberData)}`; 
  } else {
    return "";
  }
}

export default function Component({ content, overall = false }) {
  const StringMap = {
    // 1: "金獎",
    // 2: "銀獎",
    // 3: "銅獎",
  };

  const getMembersInfo = (members) => {
    if (members == null || members == undefined) {
      return ""
    }

    let infoList = members.map((m) => {
      return getDisplayInfo(m);
      // if (displayType == "groupNum") {
      //   let displayName = m.group + m.group_num;
      //   if (m.group == null || m.group == "") {
      //     displayName = "";
      //   }
      //   // use last 6 digits of phone number if empty
      //   if (displayName == "") {
      //     displayName = m.phone_num.slice(-6);
      //   }

      //   return displayName;
      // }
      // return m.name;
    });

    return infoList.join(", ")
  }


  const OverallLeaderboardItem = () => {
    let { rank, score, unit, members } = content;

    console.debug("members: ", members);
    let memberListStr = getMembersInfo(members); // members == null || members == undefined ? "" : members.map((m) => m.name).join(", ");

    const headingStr = `第${rank}名 (${score} ${unit})`;



    return (
      <Stack sx={{ width: '90%' }}
        direction={"column"} justifyContent="center" alignItems={"center"} gap={1}>
        <Box display="flex" sx={{ justifyContent: "center", columnGap: 1, alignItems: "center" }}>
          <MedalIcon rank={rank} />
          <Typography color="white" sx={
            {
              fontFamily: MAIN_FONT, fontSize: "20px", fontWeight: "900",
              letterSpacing: "0em", whiteSpace: "nowrap"
            }
          }>
            {headingStr}
          </Typography>
        </Box>

        <Box sx={{ height: '1px', background: '#aaa', width: '100%' }}></Box>
        <Typography color="white" sx={{ fontFamily: MAIN_FONT, fontSize: "18px", letterSpacing: "0em", }}>
          {memberListStr}
        </Typography>

      </Stack>
    )

    // return (
    //   <Stack
    //     direction={"row"}
    //     justifyContent="center"
    //     alignItems={"center"}
    //     gap={3}
    //   >

    //     <MedalIcon rank={rank}/>

    //     <Typography
    //       variant="h3"
    //       color="white"
    //       sx={{
    //         fontFamily: MAIN_FONT,
    //         fontSize: "10px",
    //         fontWeight: "900",
    //         // lineHeight: "80px",
    //         letterSpacing: "0em",
    //       }}
    //     >
    //       {StringMap[rank] ? (
    //         <Typography
    //           sx={{
    //             minWidth: "230px",
    //             fontFamily: MAIN_FONT,
    //             fontSize: "25px",
    //             fontWeight: "900",
    //             // lineHeight: "80px",
    //             letterSpacing: "0em",
    //           }}
    //         >
    //           {StringMap[rank]}
    //         </Typography>
    //       ) : (
    //         <Box whiteSpace="nowrap">
    //           <Typography variant="h4">{"第" + rank + "名"}</Typography>
    //         </Box>
    //       )}
    //       <Box whiteSpace="nowrap">
    //         <Typography variant="h3">
    //           {score} {unit}
    //         </Typography>
    //       </Box>
    //       <Box whiteSpace="warp">
    //         <Typography variant="h5">{memberListStr}</Typography>
    //       </Box>
    //     </Typography>
    //   </Stack >
    // );
  };


  const LeaderHeader = ({ content }) => {
    let { rank, score, unit } = content;

    return (
      <>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={1}
        >
          <Box
            component="img"
            src={MedalImgMap[rank]}
            maxWidth={24}
            maxHeight={24}
          />
          <Typography
            variant="h3"
            color="white"
            sx={{
              fontFamily: MAIN_FONT,
              fontSize: "30px",
              fontWeight: "900",
              // lineHeight: "80px",
              letterSpacing: "0em",
            }}
          >
            {StringMap[rank]} #{rank} ({score}
            {unit})
          </Typography>
        </Grid>

        <Box my={1}>
          <Divider sx={{ background: "white" }} />
        </Box>
      </>
    );
  };

  const LeaderBody = ({ content }) => {
    const { members } = content;
    if (members === undefined) {
      return <Box></Box>
    }
    return (
      <Grid
        container
        direction="row"
        justifyContent={"center"}
        alignItems={"center"}
        columnGap={2}
      >
        {members.map((_m, index) => {
          if (index > 3) return null;
          return (
            <Stack direction="row" spacing={1} key={index}>
              <Typography
                key={`memberInfoName-${index}`}
                color={"white"}
                sx={{
                  fontFamily: MAIN_FONT,
                  fontSize: "18px!important",
                  fontWeight: "900",
                  // lineHeight: "80px",
                  letterSpacing: "0em",
                }}
              >
                {/* {_m.name} */}
                {/* {memberName(_m)} */}
              </Typography>
              <Typography
                key={`memberInfoPhone-${index}`}
                color={"white"}
                sx={{
                  fontFamily: MAIN_FONT,
                  fontSize: "24px!important",
                  fontWeight: "500",
                  // lineHeight: "80px",
                  letterSpacing: "0em",
                }}
              >
                {_m.phone_num}
              </Typography>
            </Stack>
          );
        })}
      </Grid>
    );
  };

  const cardStyle = {
    border: "3px solid white",
    borderRadius: "10px",
    background: "rgba(0,0,0,0.5)",
  }

  return (
    <Box
      sx={cardStyle}
    >
      <Grid
        container
        justifyContent="center"
        alignItems={"center"}
        py={1}
        px={2}
        width={"30vw"}
      >
        <OverallLeaderboardItem content={content} />
      </Grid>
      {/* {overall ? (
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          py={1}
          px={2}
          width={"30vw"}
        >
          <OverallLeaderboardItem content={content} />
        </Grid>
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          py={2}
          px={4}
        >
          <Grid item xs={12}>
            <LeaderHeader content={content} />
          </Grid>
          <Grid item xs={12}>
            <LeaderBody content={content} />
          </Grid>
        </Grid>
      )} */}
    </Box>
  );
}
