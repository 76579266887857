import {
    Container,
    Typography,
    Box,
    Stack,
    Button,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import OrganizationMissionDetailForm from "../../components/organization/OrganizationMissionDetailForm";
import OrganizationMissionDetailFormDialog from "../../components/organization/OrganizationMissionDetailFormDialog";

export default function TestSimple() {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(1234);
    const [missionSettingID, setMissionSettingID] = React.useState(0);
    const [missionSetting, setMissionSetting] = React.useState(null);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);



    // skill: mission?.skill,
    //         missionCycle: mission?.mission_cycle || "month",
    //         calcType: mission?.calc_type || "play_count",
    //         requireValue: mission?.require_value || 10,
    //         rewardPoint: mission?.reward_point || 10,
    //         customDateRange: mission?.custom_date_range || "",
    //         reqClass: mission?.req_member_cls || "",
    //         reqGroup: mission?.req_member_group || "",

    React.useEffect(() => {
        setValue(1234);

        if (missionSettingID === 0) {
            setMissionSetting(
                {
                    id: 0,
                    mission_cycle: 'custom',
                    calc_type: 'play_score',
                    require_value: 100,
                    reward_point: 234,
                    skill: "all",
                    custom_date_range: "2024-11-23",
                    req_member_cls: "A1",
                    req_member_group: "A",
                }
            );
        }
    }, []);

    let lang = getLang();

    // "mission_cycle": "week",
    //         "skill": "all",
    //         "calc_type": "play_count",
    //         "require_value": 10,
    //         "reward_point": 20,
    // const missionSetting =

    // id: 0,
    // skill: "all",
    // missionCycle: "month",
    // calcType: "play_count",
    // requireValue: 10,
    // rewardPoint: 10,

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test Simple</Typography>



                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                    <pre>Value: {value} </pre>
                </Box>
                <Box>
                    <Button variant="contained" onClick={() => setIsDialogOpen(true)}>Show Form Dialog</Button>
                </Box>
                {
                    missionSetting &&
                    <Box>
                        <OrganizationMissionDetailFormDialog
                            orgID={13} open={isDialogOpen}
                            inputMission={missionSetting}
                            onClose={() => setIsDialogOpen(false)}
                            onUpdate={(mission) => {
                                console.debug("onUpdate: mission=", mission);
                                setMissionSetting(mission);
                                setIsDialogOpen(false);
                            }}
                        />
                        {/* <OrganizationMissionDetailForm orgID={13} inputMission={missionSetting} /> */}
                    </Box>

                }
                <pre>
                    {JSON.stringify(missionSetting, null, 2)}
                </pre>

            </Stack>
        </Container>
    )
}
