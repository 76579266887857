import React, { useState, useEffect } from "react";
/** Helper */
import { isError, getErrorMsg } from "../../../../helper/FormHelper.jsx";
import { getDataRequestPromise } from "../../../../helper/AxiosHelper";
/** Component */
import {
  Grid,
  Typography,
  Box,
  Stack,
  TextField,
  Autocomplete,
  Checkbox,
} from "@mui/material";
/** Content */
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function FormikSingleSelector({ formik, elementContent }) {
  const [options, setOptions] = useState([]);
  // const [isSetPresetValue, setIsSetPresetValue] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isSetPresetValue, setIsSetPresetValue] = useState(false);
  const [displayField, setDisplayOption] = useState(null);
  const [valueField, setValueOption] = useState(null);
  const [secondaryFilterValue, setSecondaryFilterValue] = useState(null);

  const handleOptionChange = (event, newValue) => {
    setSelectedOption(newValue);
    let selectedOption = newValue[valueField];
    formik.setFieldValue(elementContent.props.id, selectedOption);
  };

  const initSelectedOption = () => {
    setSelectedOption(null);
  };

  useEffect(() => {
    if (!formik) return;
    if (!("additionInfo" in elementContent)) return;
    if (!("secondaryFilter" in elementContent.additionInfo)) return;
    // get secondary filter field
    const { filterField, queryMapperFn } =
      elementContent.additionInfo.secondaryFilter;
    const filterValue = formik.values[filterField];
    // if filter value is null, return
    if (!filterValue)
      return console.warn("Formik Auto Complete filterValue is null");

    if (secondaryFilterValue === filterValue) return;
    setSecondaryFilterValue(filterValue);

    // console.log(elementContent.props.id);
    // console.log("Filter Value: ", formik.initialValues[elementContent.props.id]);

    // Reset selected option
    // Remap query url
    // recommended mapper function argument: (url, field, value) => `${url}&filters[${field}][$eq]=${value}`;
    const newQueryUrl = queryMapperFn(
      elementContent?.additionInfo?.queryUrl,
      filterField,
      filterValue
    );
    getReq(newQueryUrl);
  }, [formik]);

  useEffect(() => {
    const queryUrl = elementContent?.additionInfo?.queryUrl || null;
    let _displayField = elementContent?.additionInfo?.displayField || null;
    let _valueField = elementContent?.additionInfo?.valueField || null;
    if (!_displayField || !_valueField)
      return console.warn(
        "Formik Auto Complete displayField or valueField is null"
      );
    setDisplayOption(_displayField);
    setValueOption(_valueField);

    if (!queryUrl) return console.warn("Formik Auto Complete queryUrl is null");
    getReq(queryUrl);
  }, []);

  const getReq = async (url) => {
    getDataRequestPromise(`${process.env.NEXT_PUBLIC_API_URL}${url}`).then(
      (res) => {
        // console.log(res.dataSet);
        if (res.dataSet.length > 0) {
          setOptions(res.dataSet);
          // handleExistingSelectedOption(res.dataSet);
        }
      }
    );
  };

  useEffect(() => {
    if (isSetPresetValue === true) return;
    if (options.length === 0) return;
    if (!formik.values[elementContent.props.id]) return;

    handleExistingSelectedOption(options);
    setIsSetPresetValue(true);
  }, [options, formik]);

  // **
  function isObjectArray(value) {
    return (
      Array.isArray(value) && value.every((item) => typeof item === "object")
    );
  }

  const handleExistingSelectedOption = (options) => {
    if (!formik.values[elementContent.props.id]) return;
    let existOption = formik.values[elementContent.props.id];

    if (existOption) {
      let _existingSelectedOption = options.find(
        (option) => option[valueField] === existOption
      );

      if (!_existingSelectedOption) return;
      setSelectedOption(() => _existingSelectedOption);
    }
  };

  return options.length > 0 ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          value={selectedOption}
          id="option-select"
          options={options}
          getOptionLabel={(option) => {
            return option[displayField] ? option[displayField] : "";
          }}
          autoHighlight
          disableClearable
          renderOption={(props, option, { selected }) => {
            return (
              <Box component={"li"} px={2} {...props} key={option[valueField]}>
                <Box sx={{ width: "100%" }}>
                  <Stack
                    direction={"row"}
                    justifyContent={"start"}
                    alignItems={"center"}
                  >
                    {/* <Typography variant="caption" pr={1}>
                      #{option.id}
                    </Typography> */}

                    <Typography variant="h3">{option[displayField]}</Typography>
                  </Stack>
                  {/* <Typography variant="caption">
                    {option.description}
                  </Typography> */}
                </Box>
              </Box>
            );
          }}
          onChange={handleOptionChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={elementContent.props.label}
              variant="outlined"
            />
          )}
        />
      </Grid>
    </Grid>
  ) : null;
}
