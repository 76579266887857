import { Typography, Box } from "@mui/material";
import { useState, useEffect } from "react";
import React from "react";
import MockData from "../../helper/MockData";
import { AdminApi } from "../../api/AdminApi";
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import { toast } from "react-toastify";
import InfoIcon from '@mui/icons-material/Info';
import {
  Stack,
  Divider,
} from "@mui/material";
import DashboardDataGrid from "../../components/dashboard/DashboardDataGrid";
import DashboardSkillDataGrid from "../../components/dashboard/DashboardSkillDataGrid";


export default function PlayStatSection() {
  const [dashboardData, setDashboardData] = useState(null);
  const { t } = useTranslation();

  let lang = getLang();

  useEffect(() => {
    loadDashboardData();
  }, []);

  const loadDashboardData = async () => {
    let callback = (isSuccess, msg, data) => {
      console.debug("result: isSuccess=", isSuccess,
        " msg=", msg, " data=", data);
      if (isSuccess === false) {
        toast.error(msg);
        return;
      }
      setDashboardData(data);
    }
    AdminApi.getDashboardDataCallback(callback);

    // setDashboardData(MockData.getDashboardData());
    // // console.debug("currentMemberID: ", currentMemberID);
    // const result = await InfoApi.getEventList();

    // // Sort by date
    // result.sort((a, b) => {
    //   return new Date(b.start_at) - new Date(a.start_at);
    // });

    // // console.debug("result: ", result);
    // setEventList(result);
  };

  return (
    <Box>
      {dashboardData ? <Stack rowGap={2}>
        <Box display="flex" justifyContent="left  " alignItems="middle">
          <Typography variant="h3"> HSTA 活動統計數據 </Typography>
        </Box>

        <Divider> 數據總覽 </Divider>
        <DashboardDataGrid data={dashboardData} />
        <Divider> 技能資料 </Divider>
        <DashboardSkillDataGrid data={dashboardData.skill_stat} />
      </Stack>
        : <Box>載入中 ... </Box>}
    </Box>
  );
}
