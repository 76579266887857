import React, { useState, useEffect, useCallback } from "react";
/** Helper */
import { useParams, useNavigate } from "react-router-dom";

/** Component */
import { Box, Grid, Container, Paper, Backdrop } from "@mui/material";

import CommonBreadcrumb from "../../components/common/CommonBreadcrumb";

/** Api */

import GuestAppSettingForm from "../../components/app/GuestAppSettingForm";
export default function GuestAppSettingPage() {
  // router

  useEffect(() => {}, []);

  return (
    <Container maxWidth={"lg"}>
      <CommonBreadcrumb />

      <Paper sx={{ px: 3, pb: 3, mt: 6 }} elevation={3}>
        <Grid container rowSpacing={3} columnSpacing={2}>
          <Grid item xs={12}>
            <GuestAppSettingForm />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
