import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";


export default function TestKeypress() {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(1234);

    React.useEffect(() => {
        setValue(1234);
    }, []);

    const handleKeyDown = event => {
        console.log("key pressed", event.key, " repeat: ", event.repeat);
    }
    

    React.useEffect(() => {
        // listen enter key
        

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);


    let lang = getLang();

    // setValue(1234);

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test Simple</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                    <pre>Value: {value} </pre>
                </Box>
            </Stack>
        </Container>
    )
}
