import { TextField, Grid } from "@mui/material";

var FORMIK = {};

export function setFormik(_formik) {
  FORMIK = _formik;
}

const getID = (index) => {
  return `Form-Component-${new Date().getTime()}-${index}`;
};
// -------

export function isError(formik, key) {
  // console.log(formik.errors, formik.touched);
  return formik.errors[key] && formik.touched[key];
}
export function getErrorMsg(formik, key) {
  return isError(formik, key) ? formik.errors[key] : null;
}
export async function validateForm(formik) {
  await formik.validateForm();

  return !formik.isValid;
}
export function getColumnSize(formItem) {
  if (!("size" in formItem)) {
    return { xs: 12, sm: 6 };
  }
  if (typeof formItem.size === "object" && formItem.size !== null)
    return formItem.size;

  return null;
}

export const InputType = {
  INPUT: 1,
  NUMBER_INPUT: 9,
  TEXTAREA: 2,
  SINGLE_SELECTOR: 3,
  MULTI_SELECTOR: 4,
  AUTO_COMPLETE_SELECTOR: 5,
  AVATAR_UPLOAD: 6,
  IMAGE_UPLOAD: 7,
  AUTO_COMPLETE_MULTI_SELECTOR: 8,
  SWITCH: 9,
  DATE_PICKER: 10,
};
