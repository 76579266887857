import React, { useEffect } from "react";
import {
    Grid, TextField, Card, Typography, Box, Button,
    FormControl, Stack, MenuItem, InputLabel, Select,
    CardHeader, CardContent, CardActions, Dialog,
    Alert,
    Divider,
} from "@mui/material";
import { localizedName } from "../../helper/LangHelper";
import { timeToDateStr } from "../../helper/StringHelper";
import { useNavigate } from "react-router-dom";
// import { MemberApi } from "../api/MemberApi";
// import { useSelector, useDispatch } from "react-redux";
// import { memberID, updateAuthState } from "../reducers/userSlice";
import { AdminApi } from "../../api/AdminApi";
import { useFormik } from "formik";
import { use } from "i18next";
import { toast } from "react-toastify";


const SelectionControl = ({ formik, field, labelName, menuItems }) => {
    return (
        <FormControl fullWidth>
            <InputLabel>{labelName}</InputLabel>
            <Select
                fullWidth
                value={formik.values[field]}
                onChange={(event) => {
                    formik.setFieldValue(field, event.target.value);
                }}
                onBlur={(event) => {
                    formik.touched[field] = true;
                }}
            >
                {menuItems.map((item, index) => (
                    <MenuItem key={`selection-${index}`} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>

        </FormControl>
    );
};

const MissionCycleList = [
    { value: "week", label: "每週" },
    { value: "month", label: "每月" },
    { value: "custom", label: "指定 (custom)" },
]

const CalcTypeList = [
    {
        value: "play_count",
        label: "累積次數"
    },
    {
        value: "play_time",
        label: "累積時間"
    },
    {
        value: "play_score",
        label: "累積分數"
    },
    {
        value: "calories",
        label: "卡路里"
    },
]

export default function FormDialog({ orgID, open, inputMission, onClose, onUpdate }) {

    const [mission, setMission] = React.useState(inputMission);
    const [errorMessage, setErrorMessage] = React.useState("");

    useEffect(() => {
        // setMission(inputMission);
        console.debug("Form useEffect: mission=", mission);
    }, []);

    useEffect(() => {
        setMission(inputMission);
        console.debug("Form useEffect: inputMission=", inputMission);

    }, [inputMission]);



    const isCreate = () => {
        return mission.id === 0;
    }

    const getButtonLabel = () => {
        return isCreate() ? "新增" : "更新";
    }

    const getIdStrLabel = () => {
        if (isCreate()) {
            return "新任務";
        } else {
            return `任務編號: ${mission.id}`;
        }
    }

    const getDialogTitle = () => {
        return `獎勵任務設定 (${getIdStrLabel()})`
    }

    const formik = useFormik({
        initialValues: {
            id: 0,
            skill: mission?.skill,
            missionCycle: mission?.mission_cycle || "month",
            calcType: mission?.calc_type || "play_count",
            requireValue: mission?.require_value || 10,
            rewardPoint: mission?.reward_point || 10,
            customDateRange: mission?.custom_date_range || "",
            reqClass: mission?.req_member_cls || "",
            reqGroup: mission?.req_member_group || "",


            // : "",
            // skillValue: 0,
            // skillData: {},  // { left: 3, right: 3}
        },
    });

    useEffect(() => {
        console.debug("FormDialog useEffect: mission=", mission);
        // formik.setValues({
        //     id: mission.id,
        //     skill: mission.skill,
        //     missionCycle: mission.missionCycle,
        //     calcType: mission.calcType,
        //     requireValue: mission.requireValue,
        //     rewardPoint: mission.rewardPoint,
        // });

    }, [mission]);

    const isValidDateStr = (dateStr) => {
        if (dateStr === "") {
            return false;
        }
        if(dateStr.length !== 10) {
            return false;
        }
        return true;
    }


    const validateDateRange = (dateRange) => {
        if (dateRange === "") {
            return "請輸入指定日期"
        }
        let tokens = dateRange.split("~");
        const date1 = tokens[0];
        if (!isValidDateStr(date1)) {
            return "日期格式錯誤";
        }

        if (tokens.length === 1) {
            return "";
        }

        const date2 = tokens[1];
        if (!isValidDateStr(date2)) {
            return "日期格式錯誤";
        }

        return "";
    }


    const checkValueError = (values) => {
        // let msg = "";
        if (values.skill === "") {
            return "請輸入技能";
        }

        if (values.missionCycle === "") {
            return "請輸入任務週期";
        }
        if (values.calcType === "") {
            return "請輸入結算方法";
        }

        if (values.missionCycle === "custom" ) {
            let msg = validateDateRange(values.customDateRange);
            if (msg !== "") {
                return msg;
            }
            // return "請輸入指定日期";
        }

        if (values.requireValue <= 0) {
            return "請輸入需求值";
        }

        if (values.rewardPoint <= 0) {
            return "請輸入獎勵點數";
        }

        return "";
    }

    const validateValues = (values) => {
        let msg = checkValueError(values);
        if (msg !== "") {
            setErrorMessage(msg);
            return false;
        }
        return true;
    }

    const hasError = () => {
        if (errorMessage === undefined) {
            return false;
        }
        console.debug("hasError: ", errorMessage);
        return errorMessage !== null && errorMessage !== "";
    }

    const handleClose = () => {
        // console.debug("handleClose");
        onClose();
    }

    const handleSubmit = () => {
        console.debug("onSubmit: ", formik.values);
        setErrorMessage("");

        if (validateValues(formik.values) === false) {
            return;
        }

        let formData = recordToFormData(formik.values);
        console.debug("onSubmit: formData=", formData);
        console.debug("onSubmit: isCreate=", isCreate());

        if (isCreate()) {
            handleCreateRecord(formData);
        } else {
            handleUpdateRecord(formData);
        }


    }

    const formDataToRecord = (formData) => {
        let record = {
            skill: formData.skill,
            missionCycle: formData.mission_cycle,
            calcType: formData.calc_type,
            requireValue: formData.require_value,
            rewardPoint: formData.reward_point,
            id: formData.id,
        }

        return record;
    }

    const recordToFormData = (record) => {
        let formData = {
            skill: record.skill,
            mission_cycle: record.missionCycle,
            calc_type: record.calcType,
            require_value: parseInt(record.requireValue),
            reward_point: parseInt(record.rewardPoint),
            custom_date_range: record.customDateRange,
            req_member_cls: record.reqClass,
            req_member_group: record.reqGroup,
            id: record.id,
            org_id: orgID,
        }

        return formData;
    }

    const handleCreateRecord = (formData) => {
        AdminApi.createMissionSettingCallback(formData, (isSuccess, msg, data) => {
            console.debug("result: isSuccess=", isSuccess, " msg=", msg, " data=", data);
            if (isSuccess === false) {
                setErrorMessage(msg);
                return;
            }

            let newMission = formDataToRecord(data);

            setMission(newMission);

            formik.setFieldValue("id", newMission.id);
            toast.success("新增成功");

            onUpdate(data, true);
        });
    }

    const handleUpdateRecord = (formData) => {
        formData.id = mission.id;
        AdminApi.updateMissionSettingCallback(formData, (isSuccess, msg, data) => {
            console.debug("result: isSuccess=", isSuccess, " msg=", msg, " data=", data);
            if (isSuccess === false) {
                toast.error(msg);
                return;
            }

            toast.success("更新成功");

            formData.description = data.description;

            onUpdate(formData, false);
        });
    }

    return (
        <Dialog open={open} fullWidth maxWidth="md"
            scroll="body">

            <Card>
                <CardHeader title={getDialogTitle()} />

                <CardContent>
                    <Alert severity="error" sx={{ m: 2, display: hasError() ? "flex" : "none" }}>{errorMessage}
                    </Alert>

                    <Box sx={{ padding: 2 }}>
                        {/* {eventSelection} */}
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    name="skill"
                                    id="skill"
                                    label="技能"
                                    fullWidth
                                    value={formik.values.skill}
                                    onChange={formik.handleChange}
                                />
                            </Grid>

                            {/* //const SelectionControl = ({ formik, field, labelName, selectList }) => { */}
                            <Grid item xs={4}>
                                <SelectionControl formik={formik} field="missionCycle" labelName="任務週期" menuItems={MissionCycleList} />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    name="customDateRange"
                                    id="customDateRange"
                                    label="指定時段"
                                    fullWidth
                                    value={formik.values.customDateRange}
                                    onChange={formik.handleChange}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <SelectionControl formik={formik} field="calcType" labelName="結算方法" menuItems={CalcTypeList} />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    name="requireValue"
                                    id="requireValue"
                                    label="需求值"
                                    fullWidth
                                    value={formik.values.requireValue}
                                    onChange={formik.handleChange}
                                />
                            </Grid>


                            <Grid item xs={6}>
                                <TextField
                                    name="reqClass"
                                    id="reqClass"
                                    label="需求班別"
                                    fullWidth
                                    value={formik.values.reqClass}
                                    onChange={formik.handleChange}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    name="reqGroup"
                                    id="reqGroup"
                                    label="需求組別"
                                    fullWidth
                                    value={formik.values.reqGroup}
                                    onChange={formik.handleChange}
                                />
                            </Grid>

                            {/* <Grid item xs={6}>
                    <TextField
                        name="missionCycle"
                        id="missionCycle"
                        label="任務週期"
                        fullWidth
                        value={formik.values.missionCycle}
                        onChange={formik.handleChange}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        name="calcType"
                        id="calcType"
                        label="結算方法"
                        fullWidth
                        value={formik.values.calcType}
                        onChange={formik.handleChange}
                    />
                </Grid> */}





                            <Grid item xs={6}>
                                <TextField
                                    name="rewardPoint"
                                    id="rewardPoint"
                                    label="獎勵點數"
                                    fullWidth
                                    value={formik.values.rewardPoint}
                                    onChange={formik.handleChange}
                                />
                            </Grid>

                        </Grid>


                    </Box>

                    <CardActions sx={{ justifyContent: "space-between" }} >
                        <Button variant="contained" onClick={handleClose} color="error">取消</Button>
                        <Button variant="contained" onClick={handleSubmit} color="primary">{getButtonLabel()}</Button>
                    </CardActions>
                    <Divider sx={{ my: 1 }} />
                    <Stack>
                        <Typography>備註:</Typography>
                        <Typography>- 技能輸入格式：技能Code，可以用 "," 分隔，例子：situp,sitnreach；如包括全部技能，請輸入 "all"</Typography>
                        <Typography>- 指定日期格式：YYYY-MM-DD 或 YYYY-MM-DD~YYYY-MM-DD，例子：2024-11-30 或 2024-11-30~2024-12-01</Typography>
                    </Stack>
                </CardContent>
            </Card>
        </Dialog>);
}