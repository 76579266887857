import React, { useState, useEffect } from "react";
/** Helper */
import { isError, getErrorMsg } from "../../../../helper/FormHelper.jsx";
/** Component */
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
/** Content */

export default function FormikSingleSelector({ formik, elementContent }) {
  return (
    <FormControl fullWidth error={isError(formik, elementContent.props.id)}>
      <InputLabel>{elementContent.props.label}</InputLabel>
      <Select
        {...elementContent.props}
        fullWidth
        value={formik.values[elementContent.props.id]}
        multiple
        onChange={(event) => {
          formik.setFieldValue(elementContent.props.id, event.target.value);
        }}
        onBlur={(event) => {
          formik.touched[elementContent.props.id] = true;
        }}
      >
        <MenuItem value={""} disabled>
          {"Please select"}...
        </MenuItem>
        {elementContent.additionInfo.options.map((_option, index) => (
          <MenuItem key={`selection-${index}`} value={_option.value}>
            {_option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        {getErrorMsg(formik, elementContent.props.id)}
      </FormHelperText>
    </FormControl>
  );
}
