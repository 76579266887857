import * as Yup from "yup";
import { InputType } from "../../../helper/FormHelper.jsx";
const Schema = Yup.object().shape({
  show_all_ipad_games: Yup.boolean(),
  required_build: Yup.number()
    .required("Required build is required")
    .nullable()
    .notOneOf([0, null], "Required build cannot be 0 or empty"),
  new_build: Yup.number()
    .required("New build is required")
    .nullable()
    .notOneOf([0, null], "New build cannot be 0 or empty"),
});

const FormControl = [
  {
    size: { xs: 12, sm: 12 },
    additionInfo: {
      queryUrl: "/skillInfo?fields=simple",
      secondaryFilter: {
        // filterField: "vendorId",
        // queryMapperFn: (url, field, value) => {
        //   return `${url}&filters[vendor.id][$eq]=${value}`;
        // },
        resultReMapFn: (options) => {
          return options.filter((item) => item.support_ipad === true);
        },
      },
      displayField: "display_name",
      valueField: "code",
    },
    variant: InputType.AUTO_COMPLETE_MULTI_SELECTOR,

    props: {
      id: "guest_games",
      label: "Guest遊戲設定",
    },
  },
  {
    size: { xs: 12 },
    variant: InputType.SWITCH,
    props: {
      id: "show_all_ipad_games",
      label: "Guest 開放全部遊戲",
    },
  },

  {
    size: { xs: 12, sm: 6 },
    variant: InputType.INPUT,
    props: {
      id: "required_build",
      label: "Required App Build",
      type: "number",
    },
  },
  {
    size: { xs: 12, sm: 6 },
    variant: InputType.INPUT,
    props: { id: "new_build", label: "Latest Build Number", type: "number" },
  },
  {
    size: { xs: 12 },
    variant: InputType.INPUT,
    props: { id: "appstore_url", label: "AppStore Update URL", type: "text" },
  },
];

const FormikInstance = {
  initialValues: {
    guest_games: null,
    show_all_ipad_games: false,
    required_build: 0,
    new_build: 0,
    appstore_url: "",
  },
  validateOnMount: true,
  validationSchema: Schema,
};

const FormContent = {
  Schema,
  FormControl,
  FormikInstance,
};

export default FormContent;
