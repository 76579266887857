import React from "react";
import {
    Typography,
    Grid,
    Stack,
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from "@mui/material";
import MainCard from "../common/MainCard/MainCard";

// MUI Icons
import PeopleIcon from '@mui/icons-material/People';
import ManIcon from '@mui/icons-material/Man';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { Height } from "@mui/icons-material";

const convertToHour = (secondValue) => {
    if (secondValue === undefined) {
        return 0;
    }

    // Round to 1 decimal places
    return Math.round(1.0 * secondValue / 3600 * 10) / 10;
}


const style = {
    background: "#CFF5FF",
    titleBackground: "#66DAFA",
    valueColor: "black",

    listItem: {
        px: 2, py: 0,
        // backgroundColor: "orange",
    },
    listIcon: {
        p: 0,
        // backgroundColor: "red",
        pr: 1,
        minWidth: 30,
    },
    list: {
        p: 0,
        pl: 4,
        m: 0,
        flex: 1,
    }

}

const Component = ({
    title, playCount, playTime,
}) => {



    // Add comma to value 
    let playCountStr = playCount; // value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");


    let playTimeStr = convertToHour(playTime);


    return (<MainCard border={false} boxShadow={true}
        contentSX={{ p: 0, }} sx={{ height: "100%", backgroundColor: style.background }}>
        <Box sx={{
            backgroundColor: style.titleBackground, p: 2, textAlign: "center",
            flex: 1,
        }}>
            <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                {title}
            </Typography>
        </Box>
        
        <List sx={style.list}>
            <ListItem sx={style.listItem}>
                <ListItemIcon sx={style.listIcon}>
                    <ManIcon />
                </ListItemIcon>
                <ListItemText>
                    <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                        {playCountStr} 人
                    </Typography>
                </ListItemText>
            </ListItem>

            <ListItem sx={style.listItem}>
                <ListItemIcon sx={style.listIcon}>
                    <AccessTimeIcon />
                </ListItemIcon>
                <ListItemText>
                    <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                        {playTimeStr} 小時
                    </Typography>
                </ListItemText>
            </ListItem>
        </List>

    </MainCard>);

}

export default Component;


