import React, { useState, useEffect } from "react";
/** Helper */
import FormContent from "../../components/organization/orgFormSchema.jsx";
import {
  isError,
  getErrorMsg,
  validateForm,
} from "../../helper/FormHelper.jsx";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { postDataRequest, getDataRequest } from "../../helper/AxiosHelper";
import { useNavigate, useParams } from "react-router-dom";

/** Component */
import {
  Box,
  Grid,
  Typography,
  Container,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Paper,
  Button,
  Backdrop,
  CircularProgress,
  Divider,
  Tooltip,
  IconButton,
} from "@mui/material";
import CommonBreadcrumb from "../../components/common/CommonBreadcrumb";
import AiCoachingSetting from "../../components/organization/AiCoachingSettingForm";
import PersonIcon from "@mui/icons-material/Person";
import SubmitIcon from "@mui/icons-material/CheckRounded";
import RefreshIcon from "@mui/icons-material/Refresh";
import AiCoachingSettingForm from "../../components/organization/AiCoachingSettingForm";

// import Ref

/** Content */

import { Stack } from "@mui/system";
import { AdminApi } from "../../api/AdminApi.js";
/** API */

export default function Component() {
  // URL
  let { orgId } = useParams();
  // navigate
  const navigate = useNavigate();
  const formik = useFormik(FormContent.FormikInstance);
  /** function */
  const handleUpdateOrg = async (e) => {
    if (await validateForm(formik)) return;
    let formData = JSON.parse(JSON.stringify(formik.values));
    postDataRequest(
      `organization/update/${orgId}`,
      formData,
      (isSuccess, data) => {
        if (!isSuccess) {
          return toast.error("更新失敗");
        }

        toast.success("更新成功");
        return;
      }
    );
  };

  const generateURL = (loginToken) => {
    if (loginToken === null || loginToken === undefined) {
      return "";
    }

    let url = `${window.location.origin}/direct_school_login?token=${loginToken}`;
    return url;
  };

  const [isDataPadding, setDataPadding] = useState(true);
  const [currentRecordData, setCurrentRecordData] = useState(null);

  useEffect(() => {
    getDataRequest(`organization/show/${orgId}`, (isSuccess, data) => {
      if (!isSuccess) {
        console.log("Data not found with id - " + orgId);
      }
      let { record } = data;

      data.record.direct_access_url = generateURL(
        data.record.login_access_token
      );

      // turn of the backdrop
      setDataPadding(false);
      // pass the current record data to breadcrumb to set the name
      setCurrentRecordData(record);
      let formFieldList = Object.keys(FormContent.FormikInstance.initialValues);
      formFieldList.forEach((field) => {
        formik.setFieldValue(field, record[field]);
      });
    });
  }, []);

  const onRefreshAccessToken = async () => {
    // const adminUserId =
    console.debug(
      "onRefreshAccessToken. currentRecordData=",
      currentRecordData
    );

    const adminUserId = currentRecordData.admin_user_id || 0;
    const orgId = currentRecordData.id || 0;

    if (adminUserId === 0 || orgId === 0) {
      toast.error("請先設定管理員");
      return;
    }

    console.debug(
      "onRefreshAccessToken. adminUserId=",
      adminUserId,
      " orgId=",
      orgId
    );

    const result = await AdminApi.refreshOrganizationAccessToken(
      orgId,
      adminUserId
    );

    if (result === null) {
      toast.error("刷新失敗");
      return;
    }

    console.debug("onRefreshAccessToken. result=", result);
    const accessToken = result.access_token || "";
    if (accessToken === "") {
      toast.error("刷新失敗 (沒有access token)");
      return;
    }

    const newRecord = { ...currentRecordData };
    newRecord.login_access_token = accessToken;
    newRecord.direct_access_url = generateURL(accessToken);

    setCurrentRecordData(newRecord);
  };

  return (
    <Container maxWidth={"lg"}>
      {/* Backdrop */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isDataPadding}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* BreadCrumb */}
      <CommonBreadcrumb dbData={currentRecordData} />
      {/* From */}
      <Grid container columnSpacing={2}>
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={12}>
              {/* Base info form */}
              <Box component="form" sx={{ mt: 3 }}>
                <Paper sx={{ px: 3, py: 5 }} elevation={3}>
                  <Grid container rowSpacing={3} columnSpacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6" ml={1} color={"text.secondary"}>
                        基本資訊
                      </Typography>
                      <Divider sx={{ maxWidth: "40%", pt: 0.5 }} />
                    </Grid>
                    {FormContent.FormControl.map((item, index) => {
                      let readOnly = item.props.readOnly ? true : false;
                      // console.debug("readOnly=", readOnly);

                      // console.debug("index=", index, " item=", item);
                      // console.debug("props=", item.props);

                      let gridStyle = "size" in item ? item.size : { xs: 12 };
                      let formControlProps = item.props;
                      if (item.variant === "selector") {
                        return (
                          <Grid
                            item
                            key={`form-section-${index}`}
                            {...gridStyle}
                          >
                            <FormControl
                              fullWidth
                              error={isError(formik, formControlProps.id)}
                            >
                              <InputLabel>{formControlProps.label}</InputLabel>
                              <Select
                                {...formControlProps}
                                fullWidth
                                value={formik.values[formControlProps.id]}
                                onChange={(event) => {
                                  formik.setFieldValue(
                                    formControlProps.id,
                                    event.target.value
                                  );
                                }}
                                onBlur={(event) => {
                                  formik.touched[formControlProps.id] = true;
                                }}
                              >
                                <MenuItem value={""} disabled sx={{ ml: 2 }}>
                                  {item.placeHolder}
                                </MenuItem>
                                {item.options.map((_area, index) => (
                                  <MenuItem
                                    key={`area-${index}`}
                                    value={_area.value}
                                  >
                                    {_area.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>
                                {getErrorMsg(formik, formControlProps.id)}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      }
                      if (item.variant === "radio") {
                        return <></>;
                      }

                      let inputProps = {};
                      if (readOnly) {
                        inputProps.readOnly = true;
                      }

                      return (
                        <Grid item key={`form-section-${index}`} {...gridStyle}>
                          <TextField
                            {...formControlProps}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={isError(formik, formControlProps.id)}
                            helperText={getErrorMsg(
                              formik,
                              formControlProps.id
                            )}
                            value={formik.values[formControlProps.id]}
                            fullWidth
                            inputProps={inputProps}
                          />
                        </Grid>
                      );
                    })}
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="flex-end">
                        <Tooltip title="提交">
                          <Box component={"span"}>
                            <IconButton
                              onClick={handleUpdateOrg}
                              disabled={!formik.isValid}
                              size="large"
                              color="primary"
                              sx={{
                                bgcolor: "white.main",
                                borderRadius: "50%",
                                boxShadow:
                                  "rgba(0, 0, 0, 0.2) 1.95px 1.95px 5px",
                              }}
                            >
                              <SubmitIcon />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {/* AI Coaching Setting*/}

              <AiCoachingSettingForm orgId={orgId} />
            </Grid>
          </Grid>
        </Grid>
        {/* Page Sub Section */}
        <Grid item xs={3}>
          <Paper sx={{ px: 3, py: 5, mt: 3 }} elevation={3}>
            <Stack direction={"column"} spacing={2}>
              <Button
                variant="contained"
                color="success"
                sx={{ maxWidth: { xs: "auto" } }}
                endIcon={<PersonIcon />}
                onClick={() => navigate(`/organization/${orgId}/members`)}
              >
                會員
              </Button>

              <Button
                variant="contained"
                color="success"
                sx={{ maxWidth: { xs: "auto" } }}
                endIcon={<RefreshIcon />}
                onClick={onRefreshAccessToken}
              >
                刷新Access Token
              </Button>
              {/* <Button
                variant="contained"
                color="warning"
                sx={{ maxWidth: { xs: "auto" } }}
                endIcon={<EmojiEventsIcon />}
                onClick={() => navigate(`/organization/${orgId}/events`)}
              >
                活動
              </Button> */}
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
