import React, { useState, useEffect } from "react";
/** Helper */
import {
  isError,
  getErrorMsg,
  validateForm,
  getColumnSize,
  InputType,
} from "../../../helper/FormHelper.jsx";
import { postDataRequestPromise } from "../../../helper/AxiosHelper";

import { toast } from "react-toastify";

/** Component */
import {
  Box,
  Grid,
  IconButton,
  CircularProgress,
  Backdrop,
  Tooltip,
} from "@mui/material";
import FormikTextField from "./FormikTextField";
import FormikSwitch from "./FormikSwitch";
import FormikTextArea from "./FormikTextArea";
import FormikSingleSelector from "./FormikSingleSelector";
import FormikMultiSelector from "./FormikMultiSelector";
import FormikAvatarUploader from "./FormikAvatarUploader";
import FormikImageUploader from "./FormikImageUploader";
import FormikAutoCompleteSelector from "./FormikAutoCompleteSelector";
import FormikAutoCompleteMultiSelector from "./FormikAutoCompleteMultiSelector";
import FormikDateTimePicker from "./FormikDateTimePicker";
/** Content */
/** Icon */
import SubmitIcon from "@mui/icons-material/CheckRounded";

export default function CommonForm({
  fromContent,
  formik,
  onSubmitHandle = null,
  submitUrl = null,
  onSuccessExtentAction = () => {},
  debug = false,
  readOnly = false,
  hideSubmitButton = false,
}) {
  const [isProcessing, setIsProcessing] = useState(false);

  const onSubmitAction = () => {
    if (onSubmitHandle) return onSubmitHandle();

    if (submitUrl === null) return console.log("*** SubmitUrl is null ***");
    setIsProcessing(true);
    const formData = JSON.parse(JSON.stringify(formik.values));
    postDataRequestPromise(submitUrl, formData)
      .then((res) => {
        toast.success("Creation Success!");
        setIsProcessing(false);
        formik.resetForm();
        onSuccessExtentAction();
        setTimeout(() => {
          /*NOTE - Default should be page reload */
        }, 300);
      })
      .catch((err) => {
        const message = err?.data?.resultMessage.en || "";

        toast.error(`Creation Failed! ${message}`);
        console.log(err);
        setIsProcessing(false);
      });
  };
  return (
    <Grid container spacing={2}>
      <Backdrop
        open={isProcessing}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {readOnly ? (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 10,
            // pointerEvents: "none",
          }}
        ></Box>
      ) : null}

      {fromContent.FormControl.map((_formItem, index) => {
        const columnSize = getColumnSize(_formItem);
        return (
          <Grid item key={`form-item-${index}`} {...columnSize}>
            {_formItem.variant === InputType.AVATAR_UPLOAD ? (
              <FormikAvatarUploader
                formik={formik}
                elementContent={_formItem}
              />
            ) : null}
            {_formItem.variant === InputType.INPUT ? (
              <FormikTextField formik={formik} elementContent={_formItem} />
            ) : null}

            {_formItem.variant === InputType.TEXTAREA ? (
              <FormikTextArea formik={formik} elementContent={_formItem} />
            ) : null}
            {_formItem.variant === InputType.SINGLE_SELECTOR ? (
              <FormikSingleSelector
                formik={formik}
                elementContent={_formItem}
              />
            ) : null}
            {_formItem.variant === InputType.SWITCH ? (
              <FormikSwitch formik={formik} elementContent={_formItem} />
            ) : null}
            {_formItem.variant === InputType.MULTI_SELECTOR ? (
              <FormikMultiSelector formik={formik} elementContent={_formItem} />
            ) : null}
            {_formItem.variant === InputType.IMAGE_UPLOAD ? (
              <FormikImageUploader formik={formik} elementContent={_formItem} />
            ) : null}
            {_formItem.variant === InputType.AUTO_COMPLETE_SELECTOR ? (
              <FormikAutoCompleteSelector
                formik={formik}
                elementContent={_formItem}
              />
            ) : null}
            {_formItem.variant === InputType.AUTO_COMPLETE_MULTI_SELECTOR ? (
              <FormikAutoCompleteMultiSelector
                formik={formik}
                elementContent={_formItem}
              />
            ) : null}
            {_formItem.variant === InputType.DATE_PICKER ? (
              <FormikDateTimePicker
                formik={formik}
                elementContent={_formItem}
              />
            ) : null}
          </Grid>
        );
      })}

      {readOnly || hideSubmitButton ? null : (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Tooltip title="提交">
              <Box component={"span"}>
                <IconButton
                  onClick={onSubmitAction}
                  size="large"
                  disabled={!formik.isValid}
                  color="primary"
                  sx={{
                    bgcolor: "white.main",
                    borderRadius: "50%",
                    boxShadow: "rgba(0, 0, 0, 0.2) 1.95px 1.95px 5px",
                  }}
                >
                  <SubmitIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        </Grid>
      )}

      {debug ? (
        <Grid item xs={12}>
          <pre>{JSON.stringify(formik.values, null, 2)}</pre>
        </Grid>
      ) : null}
    </Grid>
  );
}
