import { Info } from "@mui/icons-material";
import {
    Container,
    Typography,
    Box,
    Button,
    Stack,
} from "@mui/material";
import { ca } from "date-fns/locale";
import React from 'react';
import { useState } from 'react';

import { AdminApi } from "../../api/AdminApi";
import { InfoApi } from "../../api/InfoApi";
import { StatApi } from "../../api/StatApi";
import { downloadWithURL } from "../../helper/ExportHelper";

export default function TestApi() {
    // const { t } = useTranslation();
    const [file, setFile] = useState(null);


    // getDashboardDataCallback
    const getDashboardDataCallback = async () => {
        let callback = (isSuccess, msg, data) => {
            console.debug("result: isSuccess=", isSuccess,
                     " msg=", msg, " data=", data);
        }
        AdminApi.getDashboardDataCallback(callback);
    }

    //getOrgnizationListCallback
    const getOrgnizationListCallback = async () => {
        let callback = (isSuccess, msg, data) => {
            console.debug("result: isSuccess=", isSuccess, " msg=", msg, " data=", data);
        }

        AdminApi.getOrgnizationListCallback(callback);
    }


    const getOrgnizationDetailCallback = async () => {
        let orgID = 1;
        let callback = (isSuccess, msg, data) => {
            console.debug("result: isSuccess=", isSuccess, " msg=", msg, " data=", data);
        }

        AdminApi.getOrgnizationDetailCallback(orgID, callback);
    }

    // const loadEventLeaderboardCallback = async (
    //     eventID,
    //     date,
    //     timeslotID,
    //     callback
    //   ) => {

    const loadEventLeaderboardCallback = async () => {
        let eventID = 14;
        // let date = "2021-09-01";
        let date = "2023-06-03";
        let timeslotID = 0;
        let callback = (result) => {
            console.debug("result: ", result);
        }
        await AdminApi.loadEventLeaderboardCallback(
            eventID, date, timeslotID, callback);
    }

    const queryMembers = async () => {
        const filters = [
            { 'field': 'user_type', 'value': 'school', 'op': 'eq' }
        ];

        const result = await InfoApi.queryMembers(1, -1, filters);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }


    const testSubmitScoreQRCode = async () => {
        let eventID = 3;
        let qrCode = "BCDPMCF";
        let skillCode = 'vjump';
        let skillScore = 33;

        // const submitScoreQRCode = async (eventID, qrCode, skillCode, skillScore) => {
        const result = await AdminApi.submitScoreQRCode(eventID, qrCode, skillCode, skillScore);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }


    // refreshOrganizationAccessToken
    const testRefreshOrganizationAccessToken = async () => {
        let orgID = 6;
        let adminUserId = 114;

        const result = await AdminApi.refreshOrganizationAccessToken(orgID, adminUserId);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }

    const testLoginWithAccessToken = async () => {
        let accessToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJvcmdJRCI6NjIsImFkbWluVXNlcklEIjo1LCJyYW4iOiI1ODBlNDk1NzI1MzAwOWI5NDliNyJ9.LlRsUfAFe0v8qQfPYPz360owJKI8Xa0YNCeAcMwKeeI";

        const result = await AdminApi.loginWithAccessToken(accessToken);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }

    const testLogin = async () => {
        // let account = "mosaic";
        // let password = "Abcd##1234";

        let account = "school";
        let password = "Abcd##1234";

        const result = await AdminApi.login(account, password);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }
    //getUserEventList

    const testGetUserEventList = async () => {
        // const userID = 5;
        const userID = 1;
        const result = await InfoApi.getUserEventList(userID);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }

    const testEventList = async () => {

        const result = await InfoApi.getEventList();
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }


    const testSkillList = async () => {

        const result = await InfoApi.getSkillList();
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }

    const testSkillDetail = async () => {

        const result = await InfoApi.getSkillDetail(1);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }


    const testSubmitScore = async () => {
        console.debug("onLogin: clicked");
        const memberID = 4;
        const eventID = 14;
        const skill = 'situp';
        const score = 60;

        const result = await AdminApi.submitScore(memberID, eventID, skill, score);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }

    const testCheckSkillInput = async () => {
        console.debug("onLogin: clicked");
        const eventID = 1;
        // const accessID = 'BCBJ';
        const qrcodeID = 'KENLKEN';
        const skill = 'situp';
        const score = 60;

        const result = await AdminApi.checkSkillInput(eventID, qrcodeID, skill, score);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }


    const loadEventOverallLeaderboard = () => {
        console.debug("onLogin: clicked");
        const eventID = 1;
        const date = '2022-12-08';
        // const timeslot = 3;
        // const skillCode = 'situp';

        AdminApi.loadEventOverallLeaderboardCallback(
            eventID, date, (result) => {
                console.debug("result: ", result);
            });

    }

    const loadSingleEventOverallLeaderboard = () => {
        console.debug("onLogin: clicked");
        const eventID = 1;
        const date = '2022-12-08';
        const queryCode = 'vjump_all';
        // const timeslot = 3;
        // const skillCode = 'situp';

        AdminApi.loadSingleEventOverallLeaderboardCallback(
            eventID, date, queryCode, (result) => {
                console.debug("result: ", result);
            });

    }

    //loadSingleEventLeaderboardCallback
    const loadSingleEventLeaderboard = () => {
        console.debug("onLogin: clicked");
        const eventID = 1;
        const date = '2022-12-07';
        const timeslot = 3;
        const skillCode = 'situp';

        AdminApi.loadSingleEventLeaderboardCallback(
            eventID, date, timeslot, skillCode, (result) => {
                console.debug("result: ", result);
            });

    }


    const loadEventLeaderboard = async () => {
        console.debug("onLogin: clicked");
        const eventID = 1;
        const date = '2022-12-08';
        const timeslot = 9;
        const showDetail = true;

        const result = await AdminApi.loadEventLeaderboard(eventID, date, timeslot, showDetail);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }

    }


    const previewHealthData = async () => {
        console.debug("onLogin: clicked");
        const eventID = 1;
        // const date = '2022-12-02';
        // const timeslot = 6;

        if (file === null) {
            alert(
                "Please select a file"
            )
            return;
        }

        try {
            const result = await AdminApi.previewHealthData(eventID, file);
            console.debug("result: ", result);
            if (result) {
                alert("Success");
            }

        } catch (error) {
            alert(
                error.message
            )
        }

    }


    const importHealthData = async () => {
        console.debug("importHealthData: clicked");
        const eventID = 1;
        // const date = '2022-12-02';
        // const timeslot = 6;

        if (file === null) {
            alert(
                "Please select a file"
            )
            return;
        }

        try {
            const result = await AdminApi.importHealthData(eventID, file);
            console.debug("result: ", result);
            if (result) {
                alert("Success");
            }

        } catch (error) {
            alert(
                error.message
            )
        }

    }
    //previewHealthData

    const getEventStat = async () => {
        console.debug("getEventStat: clicked");
        const eventID = 14;
        const date = '';

        const result = await StatApi.getEventStat(eventID, date);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }
    // queryMemberSkillData
    const queryMemberSkillData = async () => {
        console.debug("queryMembers: clicked");
        const page = 1;
        const pageSize = 50;
        // {'field':'display_name', 'value':"ken", 'op':'like'},            
        // 
        let filters = [
            // { 'field': 'display_name', 'value': "ken", 'op': 'like' },
            { 'field': 'skill_code', 'value': "moveit_ai_punch", 'op': 'eq' }
        ];
        // 

        // export const queryMemberSkillData = async (page, pageSize, filters, fields='default') => {
        const result = await InfoApi.queryMemberSkillData(
            page, pageSize, filters, 'default');
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }


    const exportMemberSkillData = async () => {
        console.debug("exportMemberSkillData: clicked");
        // 
        let filters = [
            // { 'field': 'display_name', 'value': "ken", 'op': 'like' },
            { 'field': 'skill_code', 'value': "moveit_ai_punch", 'op': 'eq' }
        ];
        // 

        // export const queryMemberSkillData = async (page, pageSize, filters, fields='default') => {
        const result = await InfoApi.exportMemberSkillData(
            filters);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }

    const testGetMemberEventHealthInfo = async () => {
        console.debug("testGetMemberEventHealthInfo: clicked");
        // 
        const eventID = 15;
        const qrCode = 'KENLKEN';
        // 

        // export const queryMemberSkillData = async (page, pageSize, filters, fields='default') => {
        AdminApi.getMemberEventHealthInfoCallback(
            eventID, qrCode, (isSuccess, msg, result) => {
                console.debug(`isSuccess=${isSuccess}, msg=${msg}`);
                console.debug(`eventID=${eventID}, qrCode=${qrCode} result: `, result);
            }
        );
    }
    //updateMemberEventHealthInfo


    const testUpdateMemberEventHealthInfo = async () => {
        console.debug("testUpdateMemberEventHealthInfo: clicked");
        // 
        const eventID = 15;
        let data = {
            "member_id": 5762,
            "event_id": 15,
            "age": 35,
            "height": 270.5,
            "weight": 70.5,
            "eye_sight_r": 3.0,
            "eye_sight_l": 2.0,
            "eye_sight_color": "P"
        }
        // 

        // export const queryMemberSkillData = async (page, pageSize, filters, fields='default') => {
        const result = await AdminApi.updateMemberEventHealthInfo(
            eventID, data, (isSuccess, msg) => {
                console.debug(`isSuccess=${isSuccess}, msg=${msg}`);
            }
        );

        console.debug(`eventID=${eventID}, data=${data} result: `, result);
    }

    console.debug("testSubmitScore: ", testSubmitScore.name);


    const test_exportHealthAndSkillSummary = async () => {
        console.debug("test_exportHealthAndSkillSummary: clicked");
        // 
        const eventID = 15;

        // export const queryMemberSkillData = async (page, pageSize, filters, fields='default') => {
        AdminApi.exportHealthAndSkillSummary(
            eventID, (isSuccess, msg, result) => {
                console.debug(`isSuccess=${isSuccess}, msg=${msg}`);
                console.debug(`eventID=${eventID} result: `, result);
                downloadWithURL(result.export_url, result.export_filename);
            }
        );
    }
    const test_getRedemptionInfoByQRcode = async () => {
        console.debug("test_getRedemptionInfoByQRcode: clicked");
        // 
        const qrcode = 'LAQBHMZ';

        // export const queryMemberSkillData = async (page, pageSize, filters, fields='default') => {
        let result = await AdminApi.getRedemptionInfoByQRcode(qrcode);

        console.debug(`qrcode=${qrcode} result: `, result);
    }

    const test_markRedeemedByID = async () => {
        console.debug("test_markRedeemedByID: clicked");
        // 
        const id = 5;

        // export const queryMemberSkillData = async (page, pageSize, filters, fields='default') => {
        let result = await AdminApi.markRedeemedByID(id);

        console.debug(`id=${id} result: `, result);
    }

    // changePasswordByPhoneNum
    const test_changePasswordByPhoneNum = async () => {
        console.debug("test_changePasswordByPhoneNum: clicked");
        // 
        const phoneNum = "99123123";
        const newPassword = "12345";

        // export const queryMemberSkillData = async (page, pageSize, filters, fields='default') => {
        let result = await AdminApi.changePasswordByPhoneNum(phoneNum, newPassword);

        console.debug(`phoneNum=${phoneNum} result: `, result);
    }
    // loadCoSportTopRanking
    const test_loadCoSportTopRanking = async () => {
        console.debug("test_loadCoSportTopRanking: clicked");

        // export const queryMemberSkillData = async (page, pageSize, filters, fields='default') => {
        let result = await AdminApi.loadCoSportTopRanking();

        console.debug(`result: `, result);
    }

    // loadCoSportAnalytics
    // loadCoSportTopRanking
    const test_loadCoSportAnalytics = async () => {
        console.debug("test_loadCoSportAnalytics: clicked");

        // export const queryMemberSkillData = async (page, pageSize, filters, fields='default') => {
        // let orgType = "primary";
        let orgType = "school";
        let result = await AdminApi.loadCoSportAnalytics(orgType);

        console.debug(`result: `, result);
    }
    // loadCoSportSkillScore

    const test_loadCoSportSkillScore = async () => {
        console.debug("test_loadCoSportSkillScore: clicked");

        // export const queryMemberSkillData = async (page, pageSize, filters, fields='default') => {
        // let orgType = "primary";
        let eventID = 22;
        let skillCode = "vjump";
        let result = await AdminApi.loadCoSportSkillScore(eventID, skillCode);

        console.debug(`result: `, result);
    }

    // ken: this is no use 
    // skillCompleteDetailByQRCode
    const test_skillCompleteDetailByQRCode = async () => {
        let eventID = 41;
        let qrCode = "FMVGNXLV";

        const result = await InfoApi.skillCompleteDetailByQRCode(qrCode, eventID);

        console.debug("result: ", result);
    }

    // getEventRedemptionDetailByQRCode
    const test_getEventRedemptionDetailByQRCode = async () => {
        let eventID = 41;
        let qrCode = "FMVGNXLV";

        const result = await AdminApi.getEventRedemptionDetailByQRCode(eventID, qrCode);
        console.debug("result: ", result);
    }

    const test_loadOrgMissionSettingList = async () => {
        let orgID = 1;
        let result = await AdminApi.loadOrgMissionSettingList(orgID);
        console.debug("result: ", result);
    }

    // createMissionSettingCallback
    const test_createMissionSettingCallback = async () => {
        let formData = {
            "org_id": 5,
            "mission_cycle": "week",
            "skill": "all",
            "calc_type": "play_count",
            "require_value": 10,
            "reward_point": 20,            
        };

        AdminApi.createMissionSettingCallback(formData, (isSuccess, msg, data) => {
            console.debug("result: isSuccess=", isSuccess, " msg=", msg, " data=", data);
        });
    }


    const test_updateMissionSettingCallback = async () => {
        let formData = {
            "id" : 11,
            "org_id": 5,
            "mission_cycle": "week",
            "skill": "all",
            "calc_type": "play_count",
            "require_value": 10,
            "reward_point": 44,            
        };

        AdminApi.updateMissionSettingCallback(formData, (isSuccess, msg, data) => {
            console.debug("result: isSuccess=", isSuccess, " msg=", msg, " data=", data);
        });
    }

    // getOrgMissionSettingCallback
    const test_getOrgMissionSettingCallback = async () => {
        let orgID = 1;
        AdminApi.getOrgMissionSettingCallback(orgID, (isSuccess, msg, data) => {
            console.debug("result: isSuccess=", isSuccess, " msg=", msg, " data=", data);
        });
        // console.debug("result: ", result);
    }

    const testList = [
        getDashboardDataCallback,
        test_getOrgMissionSettingCallback,
        test_updateMissionSettingCallback,
        test_createMissionSettingCallback,
        test_loadOrgMissionSettingList,
        test_getEventRedemptionDetailByQRCode,
        test_skillCompleteDetailByQRCode,
        test_loadCoSportSkillScore,
        test_loadCoSportAnalytics,
        test_loadCoSportTopRanking,
        testRefreshOrganizationAccessToken,
        testLoginWithAccessToken,
        test_changePasswordByPhoneNum,
        test_markRedeemedByID,
        test_getRedemptionInfoByQRcode,
        test_exportHealthAndSkillSummary,
        testUpdateMemberEventHealthInfo,
        testGetMemberEventHealthInfo,
        testGetUserEventList,
        exportMemberSkillData,
        queryMemberSkillData,
        getEventStat,
        getOrgnizationListCallback,
        loadEventLeaderboardCallback,
        getOrgnizationDetailCallback,
        queryMembers,
        testLogin,
        loadEventOverallLeaderboard,
        loadSingleEventLeaderboard,
        loadSingleEventOverallLeaderboard,
        importHealthData,
        previewHealthData,
        loadEventLeaderboard,
        testSubmitScoreQRCode,
        testEventList,
        testSkillList,
        testSkillDetail,
        testSubmitScore,
        testCheckSkillInput,

    ];

    const handleFileChange = (e) => {
        const target = e.target;
        const files = target.files;
        console.debug("files=", files);
        if (files && files.length > 0) {
            setFile(files[0]);
        }
    }

    const fileBox = file ? (
        <Box>{file.name}</Box>
    ) : (
        <Box>no file selected</Box>
    );



    return (
        <Container maxWidth="lg">
            <Typography variant="h3">Test TestApi</Typography>

            <Stack direction="row" gap={3} mb={4}  >
                <Button
                    variant="contained"
                    component="label"
                >
                    Upload File
                    <input
                        type="file"
                        hidden
                        onChange={handleFileChange}
                    />
                </Button>
                {fileBox}
            </Stack>

            <Box display="flex" direction="column" gap={3} sx={{ flexWrap: 'wrap' }}>
                {
                    testList.map((testFunc, index) => {
                        return (
                            <Button key={index} variant="outlined" color="primary"
                                onClick={
                                    testFunc
                                }>
                                {testFunc.name}
                            </Button>
                        );
                    })
                }
            </Box>
        </Container>
    )
}
