import {
    Container,
    Typography,
    Box,
    Stack,
    Divider,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import DashboardDataGrid from "../../components/dashboard/DashboardDataGrid";
import DashboardSkillDataGrid from "../../components/dashboard/DashboardSkillDataGrid";
import DashboardCard from "../../components/dashboard/DashboardStatCard";
import DashboardSkillStatCard from "../../components/dashboard/DashboardSkillStatCard";
import dashboard from "../../layouts/dashboard";
import MockData from "../../helper/MockData.js";
import PlayStatSection from "../../sections/_dashboard/PlayStatSection.js";


export default function TestSimple() {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(1234);

    React.useEffect(() => {
        setValue(1234);
    }, []);

    let lang = getLang();

    let dashboardData = MockData.getDashboardData();

    // setValue(1234);

    let formatFunc = (value) => {
        value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `+${value}`;
    }

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test Simple</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                    <pre>Value: {value} </pre>
                </Box>
                <Divider> Play Stat </Divider>
                <PlayStatSection />
                <Divider> Section </Divider>

                <DashboardDataGrid data={dashboardData} />

                <Divider> break </Divider>

                <DashboardSkillDataGrid data={dashboardData.skill_stat} />

                <DashboardSkillStatCard title="可信空手道教室"
                    playCount={1234} playTime={2233} />


                <Divider> break </Divider>

                <DashboardCard title="Testing" value={1234} color="#abc" />

                <DashboardCard title="Testing" value={1234} color="#abc" formatFunc={formatFunc} />


                <pre>
                    {JSON.stringify(dashboardData, null, 2)}
                </pre>
            </Stack>
        </Container>
    )
}
