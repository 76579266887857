import React, { useState, useEffect } from "react";
/** Helper */
import { isError, getErrorMsg } from "../../../../helper/FormHelper.jsx";
import palette from "../../../../theme/palette";
/** Component */
import { Grid, Box, ButtonBase, Badge, Avatar } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PhotoIcon from "@mui/icons-material/Photo";
/** Content */

export default function FormikAvatarUploader({ formik, elementContent }) {
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const id = `avatar-upload-${elementContent.props.id}`;
  const handleAvatarChange = (event) => {
    let file = event.target.files[0];
    setSelectedAvatar(URL.createObjectURL(file));
    formik.setFieldValue(elementContent.props.id, file);
  };
  useEffect(() => {
    const formImage = formik.values[elementContent.props.id];

    if (!formImage || formImage === "") setSelectedAvatar(null);

    if (typeof formImage === "string") {
      setSelectedAvatar(formImage);
    }
  }, [formik.values[elementContent.props.id]]);

  return (
    <Grid
      container
      direction="column"
      justifyContent={"center"}
      alignItems={"center"}
    >
      <input
        accept="image/*"
        id={id}
        type="file"
        style={{ display: "none" }}
        onChange={handleAvatarChange}
      />
      <ButtonBase>
        <label htmlFor={id} style={{ cursor: "pointer" }}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <Box
                p={0.5}
                mt={1.5}
                ml={1}
                sx={{
                  borderRadius: "50%",
                  bgcolor: "background.default",
                  border: `4px solid ${palette.background.paper}`,
                }}
              >
                <ModeEditIcon sx={{ fontSize: "0.7rem" }} />
              </Box>
            }
          >
            <Avatar
              variant="rounded"
              src={selectedAvatar}
              sx={{ width: "75px", height: "75px" }}
            >
              <PhotoIcon sx={{ fontSize: "3rem" }} />
            </Avatar>
          </Badge>
        </label>
      </ButtonBase>
    </Grid>
  );
}
