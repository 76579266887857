import React, { useState, useEffect } from "react";
/** Helper */
import { isError, getErrorMsg } from "../../../../helper/FormHelper.jsx";
import { getDataRequestPromise } from "../../../../helper/AxiosHelper";
/** Component */
import {
  Grid,
  Typography,
  Box,
  Stack,
  TextField,
  Autocomplete,
  Checkbox,
} from "@mui/material";
/** Content */
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function FormikAutoCompleteMultiSelector({
  formik,
  elementContent,
}) {
  const [options, setOptions] = useState([]);
  // const [isSetPresetValue, setIsSetPresetValue] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [isSetPresetValue, setIsSetPresetValue] = useState(false);
  const [displayField, setDisplayOption] = useState(null);
  const [valueField, setValueOption] = useState(null);

  const handleOptionChange = (event, newValue) => {
    console.log(newValue);
    setSelectedOption(newValue);
    let selectedOption = newValue.map((option) => option[valueField]);
    formik.setFieldValue(elementContent.props.id, selectedOption);
  };

  useEffect(() => {
    const queryUrl = elementContent?.additionInfo?.queryUrl || null;
    let _displayField = elementContent?.additionInfo?.displayField || null;
    let _valueField = elementContent?.additionInfo?.valueField || null;
    let secondaryFilterFn =
      elementContent?.additionInfo?.secondaryFilter?.resultReMapFn || null;
    if (!_displayField || !_valueField)
      return console.warn(
        "Formik Auto Complete displayField or valueField is null"
      );
    setDisplayOption(_displayField);
    setValueOption(_valueField);

    if (!queryUrl) return console.warn("Formik Auto Complete queryUrl is null");
    getDataRequestPromise(`${queryUrl}`).then((res) => {
      if (res.result.length > 0) {
        let queryResult =
          secondaryFilterFn != null
            ? secondaryFilterFn(res.result)
            : res.result;
        setOptions(queryResult);

        // handleExistingSelectedOption(res.dataSet);
      }
    });
  }, []);

  useEffect(() => {
    if (isSetPresetValue === true) return;
    if (options.length === 0) return;
    if (!formik.values[elementContent.props.id]) return;
    // if (!formik.values[elementContent.props.id]) return;
    handleExistingSelectedOption(options);
    setIsSetPresetValue(true);
  }, [options, formik]);

  // **
  function isObjectArray(value) {
    return (
      Array.isArray(value) && value.every((item) => typeof item === "object")
    );
  }
  function isSimpleArray(value) {
    return Array.isArray(value) && !isObjectArray(value);
  }

  const handleExistingSelectedOption = (options) => {
    if (!formik.values[elementContent.props.id]) return;
    let existOptions = [];

    if (isObjectArray(formik.values[elementContent.props.id])) {
      existOptions = formik.values[elementContent.props.id].map(
        (option) => option[valueField]
      );
      formik.setFieldValue(elementContent.props.id, existOptions);
    } else {
      existOptions = formik.values[elementContent.props.id];
    }
    // console.log(options);

    // console.log("form value", formik.values[elementContent.props.id]);
    // console.log(isObjectArray(formik.values[elementContent.props.id]));
    // console.log(existOptions);
    let _existingSelectedOption = options.filter((option) => {
      return existOptions.includes(option[valueField]);
    });
    // console.log(options);
    // console.log(_existingSelectedOption);
    setSelectedOption(() => _existingSelectedOption);
    // setSelectedOption(() => formik.values[elementContent.props.id]);
  };

  return options.length > 0 ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          value={selectedOption}
          id="option-select"
          options={options}
          getOptionLabel={(option) => {
            return option[displayField];
          }}
          autoHighlight
          disableClearable
          isOptionEqualToValue={(option, value) =>
            option[valueField] == value[valueField]
          }
          renderOption={(props, option, { selected }) => {
            return (
              <Box component={"li"} px={2} {...props} key={option[valueField]}>
                <Box sx={{ width: "100%" }}>
                  <Stack
                    direction={"row"}
                    justifyContent={"start"}
                    alignItems={"center"}
                  >
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      sx={{ mr: 1 }}
                      checked={selected}
                    />
                    <Typography variant="body1">
                      {option[displayField]}
                    </Typography>
                    {/* <Typography variant="caption">#{option.id}</Typography> */}
                  </Stack>
                  {/* <Typography variant="caption">
                    {option.description}
                  </Typography> */}
                </Box>
              </Box>
            );
          }}
          onChange={handleOptionChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={elementContent.props.label}
              variant="outlined"
            />
          )}
        />
      </Grid>
    </Grid>
  ) : null;
}
