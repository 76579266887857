import * as Yup from "yup";
import { InputType } from "../../../helper/FormHelper.jsx";
const Schema = Yup.object().shape({});

const FormControl = [
  {
    size: { xs: 12, sm: 12 },
    additionInfo: {
      queryUrl: "/skillInfo?fields=simple",
      secondaryFilter: {
        // filterField: "vendorId",
        // queryMapperFn: (url, field, value) => {
        //   return `${url}&filters[vendor.id][$eq]=${value}`;
        // },
        resultReMapFn: (options) => {
          return options.filter((item) => item.support_ipad == true);
        },
      },
      displayField: "display_name",
      valueField: "code",
    },
    variant: InputType.AUTO_COMPLETE_MULTI_SELECTOR,
    props: {
      id: "ipad_games",
      label: "遊戲設定",
    },
  },
  {
    size: { xs: 12, sm: 6 },
    variant: InputType.SWITCH,
    props: {
      id: "show_all_ipad_games",
      label: "開放全部遊戲",
    },
  },
  {
    size: { xs: 12, sm: 6 },
    variant: InputType.SWITCH,
    props: {
      id: "enable_vital_watch",
      label: "是否開通知能手錶",
    },
  },
  {
    size: { xs: 12, sm: 6 },
    variant: InputType.DATE_PICKER,
    props: {
      id: "subscription_end_at",
      label: "訂閱結束時間 ",
      inputFormat: "YYYY/MM/DD ",
    },
    views: ["year", "day"],
  },
];

const FormikInstance = {
  initialValues: {
    ipad_games: null,
    show_all_ipad_games: false,
    enable_vital_watch: false,
    subscription_end_at: "",
  },
  validateOnMount: true,
  validationSchema: Schema,
};

const FormContent = {
  Schema,
  FormControl,
  FormikInstance,
};

export default FormContent;
