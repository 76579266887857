import React from "react";
/** Helper */
import { isError, getErrorMsg } from "../../../../helper/FormHelper.jsx";
/** Component */
import { FormControlLabel, Switch, Typography } from "@mui/material";

/** Content */
export default function FormikSwitch({ formik, elementContent }) {
  return (
    <FormControlLabel
      label={
        <Typography variant="body1" color={"text.secondary"}>
          {elementContent.props.label}
        </Typography>
      }
      // labelPlacement={"start"}
      control={
        <Switch
          {...elementContent.props}
          checked={formik.values[elementContent.props.id] ?? false}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={isError(formik, elementContent.props.id)}
        />
      }
    />
  );
}
