import * as Yup from "yup";
import { InputType } from "../../../../helper/FormHelper.jsx";
const Schema = Yup.object().shape({

});

const FormControl = [

  {
    size: { xs: 12, sm: 12 },
    variant: InputType.IMAGE_UPLOAD,
    props: {
      id: "photo",
      label: "Face相片",
      type: "string",
    },
  },
];

const FormikInstance = {
  initialValues: {
    photo: null,
  },
  validateOnMount: true,
  validationSchema: Schema,
};

const FormContent = {
  Schema,
  FormControl,
  FormikInstance,
};

export default FormContent;
