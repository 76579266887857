import React, { useState, useEffect } from "react";
/** Helper */
import { isError, getErrorMsg } from "../../../../helper/FormHelper.jsx";
/** Component */
import { TextField } from "@mui/material";
/** Content */

export default function FormikTextField({ formik, elementContent }) {
  return (
    <TextField
      {...elementContent.props}
      value={formik.values[elementContent.props.id] ?? ""}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      error={isError(formik, elementContent.props.id)}
      helperText={getErrorMsg(formik, elementContent.props.id)}
      multiline
      rows={6}
      fullWidth
    />
  );
}
