import {
    getDeviceSecretKey,
    getLocalStorageUser,
    removeLocalStorageUser,
} from "./LocalStorageHelper";
import ReactSwal from "../helper/AlertHelper";
import { Translation } from "react-i18next";

const kRoleLevelAdmin = 1;  // super admin 
const kRoleLevelAdmin2 = 2; // minor admin
const kRoleLevelNormal = 0;

export const getRoleLevel = (role) => {
    return role; // Same as role value
};

export const isAdminUser = (user) => {
    if (!user) {
        return false;
    }
    console.debug("isAdminUser: role=", user.role);
    return user.role >= kRoleLevelAdmin;
};

export const getPagePermission = (page) => {
    // Page: location.pathname;
    console.log("getPagePermission: ", page);
    if (page === "/login") {
        return null;
    }

    if (page === "/") {
        return null;
    }

    if (page.startsWith("/user")) {
        return [kRoleLevelNormal];
    }

    // /if(page.startsWith("/admin")) {

    return [kRoleLevelAdmin, kRoleLevelAdmin2];
};

export const checkUserRole = (user, page, action) => {
    console.debug("checkUserRole: ", user, " page: ", page, " action: ", action);

    if (page === "/login") {
        return true;
    }

    if (!user) {
        return false;
    }

    // const roleLevel = getRoleLevel(user.role);
    const permissionSet = getPagePermission(page);
    console.debug(
        "checkUserRole: permissionSet: ",
        permissionSet,
        " userRole=",
        user.role
    );
    if (permissionSet == null) {
        // No need permission
        return true;
    }

    return permissionSet.includes(user.role);
};

// ken: Advance usage
export const checkPermission = (user, page, action) => {
    try {
        var permissionList = [];

        if (!user) {
            return false;
        }

        if (!user.permissions || user.permissions.length === 0) {
            return false;
        }

        for (const [key, value] of Object.entries(user.permissions)) {
            permissionList.push({
                page: key,
                action: value,
            });
        }

        var havePermission = false;

        permissionList.forEach((permission) => {
            if (permission.page == page && permission.action.includes(action)) {
                havePermission = true;
                return;
            }
        });

        return havePermission;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const checkPagePermission = (user, page, action, navigate) => { };

export const checkSuperAdminPermission = (user) => {
    return user.role === 1;
};

export const checkPageByUserRole = (user, page, action, navigate) => {
    // return true;
    console.debug("checkPageUserRole: user=", user);
    if (checkUserRole(user, page, action)) {
        return true;
    }

    // if(navigate != null) {
    //     navigate("/");
    //     return;
    // }

    // window.location.href = "/";
    // // alert("No permission");

    // return false;
    // Fancy alert, but have some issue
    ReactSwal.fire({
        title: <Translation>{(t) => <>{t("error.title")}</>}</Translation>,
        html: <Translation>{(t) => <>{t("error.no_permission")}</>}</Translation>,
        allowOutsideClick: false,
    }).then((result) => {
        if (result.isConfirmed) {
            window.location.href = "/";
        }
    });

    return false;
};
