import React from "react";
import {
    Typography,
    Grid,
    Stack,
    Box,
} from "@mui/material";
import DashboardStatCard from "./DashboardStatCard";


const convertToHour = (secondValue) => {
    if (secondValue === undefined) {
        return 0;
    }

    // Round to 1 decimal places
    return Math.round(1.0 * secondValue / 3600 * 10) / 10;
}

const convertToInteger = (value) => {
    if (value === undefined) {
        return 0;
    }
    return Math.round(value);
}


const Component = ({
    data,
}) => {

    let totalPlayCount = convertToInteger(data?.all_time?.total_play_count);
    let totalPlayTime = convertToHour(data?.all_time?.total_play_second);
    let newPlayCount = convertToInteger(data?.one_day?.total_play_count);
    let newPlayTime = convertToHour(data?.one_day?.total_play_second);
    let totalMemberCount = convertToInteger(data?.total_member);

    // // Add comma to value 
    // let valueStr = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // let iconComponent = GetIcon("school");

    const gridSx = {
        px: 1,
        // backgroundColor: "blue",
    }

    let addPlusSign = (value) => {
        value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `+${value}`;
    }


    const gridData = [
        // { title: "會員總人數", value: totalMemberCount, color: "#DEA2A9", icon: "people", },
        { title: "總參與人數", value: totalPlayCount, color: "#DEF249", icon: "people", },
        { title: "今天新參與人數", value: newPlayCount, color: "#97D955", icon: "people", formatFunc: addPlusSign },
        { title: "總參與時數(小時)", value: totalPlayTime, color: "#FFDE78", icon: "time" },
        { title: "今天新參與時數(小時)", value: newPlayTime, color: "#F5BD3D", icon: "time", formatFunc: addPlusSign },
    ]

    return (
        <Grid container alignItems="stretch"  direction="row"  sx={{
            alignItems: "stretch",
            height: "100%",
            // backgroundColor: "red",
        }}>
            {
                gridData.map((data, index) => {
                    return (
                        <Grid item xs={6} sm={2} sx={gridSx} key={index}>
                            <DashboardStatCard
                                title={data.title} value={data.value}
                                color={data.color} icon={data.icon}
                                formatFunc={data.formatFunc}
                                 />
                        </Grid>
                    )
                })
            }
            {/* <Grid item xs={12}>
                <pre>
                    {JSON.stringify(data, null, 2)}
                </pre>
            </Grid> */}
        </Grid>
    );

}

export default Component;


