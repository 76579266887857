import { useTheme } from '@mui/material/styles';
import { Grid, Divider, Container, Typography, Breadcrumbs } from '@mui/material';
import EventListSection from '../sections/_dashboard/EventListSection';

// ----------------------------------------------------------------------

export default function MainEventList() {
  const theme = useTheme();

  return (
    <>
      <title> Event List </title>


      <Container maxWidth="xl">
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">Home</Typography>
        </Breadcrumbs>

        <Typography variant="h4" sx={{ mb: 5 }}>
          活動總覽
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EventListSection />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
