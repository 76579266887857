import React from "react";
import {
    Typography,
    Grid,
    Stack,
    Box,
} from "@mui/material";
import MainCard from "../common/MainCard/MainCard";

// MUI Icons
import PeopleIcon from '@mui/icons-material/People';
import ManIcon from '@mui/icons-material/Man';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ApartmentIcon from '@mui/icons-material/Apartment';

const GetIcon = (iconType) => {
    switch (iconType) {
        case "people":
            return <ManIcon fontSize="large" />;
        case "time":
            return <AccessTimeIcon fontSize="large" />;
        case "school":
            return <ApartmentIcon fontSize="large" />;
        default:
            return <></>;
    }
}


const Component = ({
    title, value, color, icon, formatFunc,
}) => {

    // Add comma to value 
    let valueStr = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (formatFunc !== undefined) {
        valueStr = formatFunc(value);
    }


    let iconComponent = GetIcon(icon);


    return (<MainCard border={false} boxShadow={true}
        contentSX={{ p: 1 }} sx={{ backgroundColor: color, p:0 }}>
        <Stack direction="row">
            {/* Icon */}
            <Box sx={{ pr: 1, alignContent: "center" }}>
                {iconComponent}
            </Box>

            {/* Information */}
            <Stack>

                <Grid container alignItems="center">
                    <Grid item>
                        <Typography fontSize={35} fontWeight={400}>
                            {valueStr}
                        </Typography>
                    </Grid>
                </Grid>
                <Typography fontSize={12} fontWeight={800}>
                    {title}
                </Typography>
            </Stack>
        </Stack>

    </MainCard>);

}

export default Component;


