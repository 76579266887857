import React, { useState, useEffect } from "react";
/** Helper */
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  postDataRequestPromise,
  getDataRequestPromise,
} from "../../../helper/AxiosHelper.js";
import { getlocalStorageUserObject } from "../../../helper/LocalStorageHelper";
import { checkSuperAdminPermission } from "../../../helper/PermissionHelper";
/** Component */
import { Box, Grid, Typography, Backdrop, Divider } from "@mui/material";
import CommonForm from "../../common/CommonForm/index.jsx";
import FormContent from "./guestAppSettingFormSchema.js";

/** Content */
const debug = false;
export default function GuestAppSettingForm() {
  // init formik
  const [isDataPadding, setDataPadding] = useState(true);
  const [isReadyOnly, setIsReadOnly] = useState(false);
  const formik = useFormik(FormContent.FormikInstance);
  const onSubmitHandle = () => {
    let formData = formik.values;
    if (isReadyOnly) return;
    // console.log(device);

    postDataRequestPromise(`/systemSetting/update`, formData)
      .then((res) => {
        // formik.resetForm();
        toast.success("App Setting 更新成功!");
      })
      .catch((err) => {
        // Please check the data structure
        const message = err?.data?.resultMessage.en || "";

        toast.error(`更新失敗! ${message}`);
        console.log(err);
      });
  };
  useEffect(() => {
    var user = getlocalStorageUserObject();
    let hasPerm = checkSuperAdminPermission(user);

    setIsReadOnly(!hasPerm);

    getDataRequestPromise(`/systemSetting`)
      .then((res) => {
        if (res.result) {
          let formFieldList = Object.keys(
            FormContent.FormikInstance.initialValues
          );
          formFieldList.forEach((field) => {
            // console.log(field, record[field]);
            formik.setFieldValue(field, res.result[field]);
          });
          setDataPadding(false);
        }
      })
      .catch((err) => {
        // Please check the data structure
        setDataPadding(false);
        console.log(err);
      });
  }, []);

  return (
    <Grid container rowSpacing={3} columnSpacing={2}>
      {/* Backdrop */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isDataPadding}
      ></Backdrop>
      <Grid item xs={12}>
        <Typography variant="h6" ml={1} color={"text.secondary"}>
          AI Coaching App (iPad) 設定 (BETA)
        </Typography>
        <Divider sx={{ maxWidth: "40%", pt: 0.5 }} />
      </Grid>
      <Grid item xs={12}>
        <CommonForm
          readOnly={isReadyOnly}
          fromContent={FormContent}
          formik={formik}
          onSubmitHandle={onSubmitHandle}
          debug={debug}
        />
      </Grid>
    </Grid>
  );
}
