import React, { useState, useEffect } from "react";
/** Helper */
/** Component */
import {
  Box,
  Grid,
  Typography,
  Container,
  FormControl,
  MenuItem, InputLabel, Select,
  Card,
  CardContent,
  Stack,
  Divider,
  Button,
  IconButton,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import FormContent from "./MemberFaceFormSchema";
import { Field, Form, Formik } from "formik";
import CommonForm from "../../../common/CommonForm";
import { useFormik } from "formik";
import { AdminApi } from "../../../../api/AdminApi";
import { convertBase64 } from "../../../../helper/UnitsHelper";

export default function MemberFaceForm({ memberID, faceID = "" }) {
  const [currentFaceID, setCurrentFaceID] = useState(faceID);

  const formik = useFormik(FormContent.FormikInstance);


  const hasFaceId = currentFaceID !== "";



  //   // /// 
  //   // function onSubmit() {
  //   //   const recordList = getFinalRecordList();
  //   //   console.log("parsedCsvContent: ", recordList);
  //   //   let insertData = {
  //   //     event_id: eventID,
  //   //     skill_code: skill.code,
  //   //     id_type: idType,
  //   //     record_list: recordList, // Object.values(parsedCsvContent),
  //   //   };
  //   //   console.log("insertData: (onSubmit) ", insertData);
  //   //   AdminApi.batchInsertSkillScore(insertData, (isSuccess) => {
  //   //     if (isSuccess) {
  //   //       toast.success("更新成功");
  //   //     }
  //   //   });

  //   //   setParsedCsvContent((pre) => null);
  //   //   removeUploadFile();
  //   //   handleDialogClose();
  // }

  const onClearFaces = async () => {
    const _callback = (isSuccess, msg, result) => {
      // console.debug("onClearFaces: isSuccess: ", isSuccess);
      // console.debug("onClearFaces: msg: ", msg);
      // console.debug("onClearFaces: result: ", result);
      if (isSuccess === false) {
        alert(msg);
        return;
      }

      setCurrentFaceID("");
    }

    await AdminApi.clearMemberFace(memberID, _callback);

  };

  const onEnrollPhoto = async () => {
    console.debug("onEnrollPhoto: ", formik.values);

    console.debug("photo: ", formik.values.photo);

    let imageData = null;
    imageData = formik.values.photo
    // await getUploadImageData(formik.values.photo);

    let data = {
      member_id: memberID,
      photo: imageData,
    };
    //
    console.debug("data: ", data);
    // return;

    const _callback = (isSuccess, msg, result) => {
      console.debug("enrollMemberFace: isSuccess: ", isSuccess);
      console.debug("enrollMemberFace: msg: ", msg);
      console.debug("enrollMemberFace: result: ", result);
      if (isSuccess === false) {
        alert("xxx" + msg);
        return;
      }

      setCurrentFaceID("enrolled");
      setFieldValue("photo", null);
      formik.resetForm();
      // console.debug("enrollMemberFace: msg: ", msg);
      // console.debug("enrollMemberFace: result: ", result);

    };

    console.debug("enrollMemberFace: before call");
    // export const queryMemberSkillData = async (page, pageSize, filters, fields='default') => {
    await AdminApi.enrollMemberFace(memberID, data, _callback);
    // console.debug("enrollMemberFace***: result: ", result);

    // if (isSuccess === false) {
    //   // alert("msg");
    // } else {
    //   formik.setFieldValue("photo", "");

    // }
    //await AdminApi.submitScore(memberID, eventID, skill, score);
  };

  const setFieldValue = (field, value) => {
    formik.setFieldValue(field, value);
  }

  return (
    <Box>
      <Card sx={{borderRadius:1}}>
        <CardContent>
          <Stack spacing={3} direction={"row"} justifyContent={"space-between"}>
            <Typography variant="h6" sx={{ mt: 1 }}>
              FaceID 設定
            </Typography>


          </Stack>
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            {
              hasFaceId ? "已設定FaceID" : "未設定"
            }
          </Box>

          <Divider sx={{ mt: 2 }} />
          <Box sx={{ pt: 2 }}>
            {/* <CommonForm
              fromContent={FormContent}
              formik={formik}
              hideSubmitButton
            /> */}
            <Formik
              initialValues={{ file: null }}
              onSubmit={(values) => {
                // Handle file upload logic here
                console.log('File submitted:', values.file);
              }}
            >
              <Form>
                <Field
                  name="photo"
                  type="file"
                  onChange={(event) => {
                    setFieldValue("photo", event.currentTarget.files[0]);
                  }}
                />
                {/* <button type="submit">Submit</button> */}
              </Form>

            </Formik>
            <Button sx={{ my: 2 }} variant="contained" color="primary" onClick={onEnrollPhoto}>
              加入相片
            </Button>
          </Box>
          {
            hasFaceId && <>
              <Divider sx={{ my: 2 }} />
              <Button variant="contained" color="error" onClick={onClearFaces}>
                刪除FaceID
              </Button>
            </>
          }


          {/* <pre>
            memberID: {memberID}
            <br />
            currentFaceID: {currentFaceID}
            <br />
            {JSON.stringify(formik.values)}
          </pre> */}


        </CardContent>
      </Card>
    </Box >
  );
}
