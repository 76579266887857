import React from "react";
import {
    Typography,
    Grid,
    Stack,
} from "@mui/material";
import MainCard from "../MainCard/MainCard";


const CommonDashboardCard = ({
    title, value, color,
}) => {
    return (<MainCard  border={false} boxShadow={true} contentSX={{ p: 2, color }}>
        <Stack>
            <Typography variant="h6" color="textSecondary">
                {title}
            </Typography>
            <Grid container alignItems="center">
                <Grid item>
                    <Typography variant="h4" color="inherit">
                        {value}
                    </Typography>
                </Grid>
            </Grid>
        </Stack>
    </MainCard>);

}

export default CommonDashboardCard;


