import React, { useState, useEffect } from "react";
/** Helper */
import { isError, getErrorMsg } from "../../../../helper/FormHelper.jsx";
import palette from "../../../../theme/palette";
/** Component */
import { Grid, Box, TextField, InputAdornment } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
/** Content */

export default function FormikImageUploader({ formik, elementContent }) {
  const [selectedImage, setSelectedImage] = useState(
    "/default/default_image.png"
  );
  const id = `image-upload-${elementContent.props.id}`;
  const handleImageChange = (event) => {
    let file = event.target.files[0];

    if (!file) return;
    setSelectedImage(URL.createObjectURL(file));
    formik.setFieldValue(elementContent.props.id, file);
  };

  useEffect(() => {
    if (formik.values[elementContent.props.id]) return;

    if (!elementContent?.additionInfo?.displayField)
      return console.warn("FormikImageUploader displayField is null");
    if (!formik.values[elementContent?.additionInfo?.displayField]) return;

    const formImage = formik.values[elementContent?.additionInfo?.displayField];

    if (typeof formImage === "string") {
      setSelectedImage(formImage);
    }

    if (!formImage || formImage === "")
      setSelectedImage("/default/default_image.png");
  }, [formik]);

  return (
    <Grid container direction="column" gap={2} sx={{ borderShadow: 13 }} p={0}>
      <Grid item>
        <TextField
          {...elementContent.props}
          disabled
          sx={{ bgcolor: "#FFF", color: "" }}
          value={formik?.values[elementContent.props.id]?.name || ""}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ImageIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <input
        accept="image/*"
        id={id}
        type="file"
        style={{ display: "none" }}
        onChange={handleImageChange}
        // {...elementContent?.props}
      />
      <label htmlFor={id} style={{ cursor: "pointer", background: "#F4F6F8" }}>
        <Box width={"100%"} height={"100%"}>
          <Box
            component={"img"}
            src={selectedImage}
            sx={{
              objectFit: "contain",
              objectPosition: "center",
              width: "100%",
              height: "300px",
            }}
          />
        </Box>
      </label>
    </Grid>
  );
}
